import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { FeatureStateSymbol, PageFeatureConfigSymbol, Props } from '@wix/thunderbolt-symbols'
import { CompareDataDeep, HaveEqualBackgrounds, PageTransitionsWillMountFactory } from './types'
import { name, PageTransitionsCompletedSymbol } from './symbols'

const PROPS_TO_COMPARE = [
	'type',
	'alignType',
	'fittingType',
	'scrollType',
	'colorOverlay',
	'colorOverlayOpacity',
	'color',
	'videoId',
	'uri',
	'opacity',
]

const compareDataDeep: CompareDataDeep = (prevData, currentData, refKeys, propsToCheck) => {
	// @ts-ignore
	const equal = propsToCheck.every((key: string) => (prevData && prevData[key]) === (currentData && currentData[key]))
	return (
		equal &&
		refKeys.every((ref: string) =>
			prevData || currentData
				? // @ts-ignore
				  compareDataDeep(prevData && prevData[ref], currentData && currentData[ref], refKeys, propsToCheck)
				: true
		)
	)
}

const haveEqualBackgrounds: HaveEqualBackgrounds = (currentPageBackground, prevPageBackground) => {
	if (!prevPageBackground) {
		return false
	}

	// prev page background media data
	const prevMediaData = prevPageBackground.mediaRef
	const prevMediaDataType = prevMediaData && prevMediaData.type
	// current page background media data
	const currentMediaData = currentPageBackground.mediaRef
	const currentMediaDataType = currentMediaData && currentMediaData.type

	const isOnlyColor = !prevMediaData && !currentMediaData
	const isMediaTypeEqual = isOnlyColor || prevMediaDataType === currentMediaDataType
	const shouldIgnoreColor = prevMediaDataType === 'WixVideo' && isMediaTypeEqual

	const refKeys = ['mediaRef', 'imageOverlay']
	let propsToCheck = [...PROPS_TO_COMPARE]
	if (shouldIgnoreColor) {
		const colorIndex = propsToCheck.indexOf('color')
		propsToCheck.splice(colorIndex, 1)
	} else if (isOnlyColor) {
		propsToCheck = ['color']
	}

	return isMediaTypeEqual && compareDataDeep(prevPageBackground, currentPageBackground, refKeys, propsToCheck)
}

const pageTransitionsWillMountFactory: PageTransitionsWillMountFactory = (
	pageFeatureConfig,
	propsStore,
	pageTransitionsCompleted,
	featureState
) => {
	return {
		pageWillMount(pageId) {
			const state = featureState.get()
			const transitionEnabled = state ? state.nextTransitionEnabled : true

			const prevPageBg = state?.pageBackground
			const currentPageBg = pageFeatureConfig.pageBackground

			propsStore.update({
				SITE_PAGES: {
					transitionEnabled,
					onTransitionComplete: () => {
						pageTransitionsCompleted.notifyPageTransitionsCompleted(pageId)
					},
				},
				BACKGROUND_GROUP: {
					transitionEnabled: transitionEnabled && !haveEqualBackgrounds(currentPageBg, prevPageBg),
				},
			})

			featureState.update(() => ({
				nextTransitionEnabled: true,
				pageBackground: currentPageBg,
			}))
		},
	}
}

export const PageTransitionsWillMount = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, PageTransitionsCompletedSymbol, named(FeatureStateSymbol, name)],
	pageTransitionsWillMountFactory
)
